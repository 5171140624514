import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { ApiEndpoints } from 'src/app/core/infrastructure/application.config';
import { ProductBooking } from 'src/app/core/models/product/product-booking.model';
import { Booking } from 'src/app/core/models/booking/booking.model';
import { Booking as BookingInterface } from 'src/app/core/models/booking.model';
import { map } from 'rxjs/internal/operators/map';
import { ContentType } from '../utils/http.utils';
import { PaymentOrigin } from '../enums/payment-origin.enum';
import { BookingStatus } from '../enums/bookig-status.enum';
import { ProductTypeId } from '../enums/product-type.enum';
import { PricingType } from '../enums/pricing-type.enum';
import { ProductStatusInt } from '../enums/product-status.enum';
import { GridFiltersType, AdditionalFilters } from 'src/app/controls/grid/helpers/grid-filter.utils';
import { GridLoadOptions, GridPageData } from 'src/app/controls/grid/helpers/grid.model';

export interface BookingsFilters extends GridFiltersType {
  paymentOrigin: PaymentOrigin[];
  optionId: number[];
  partnerId: number[];
  status: BookingStatus[];
  customerId: number[];
}

export interface AdditionalBookingsFilter extends AdditionalFilters {
  partnerCountryId?: number;
  partnerRegionId?: number;
  experienceDateFrom?: string;
  experienceDateTo?: string;
  createdDateFrom?: string;
  createdDateTo?: string;
  referenceNumbers?: string[];
  customerEmails?: string[];
}

@Injectable()
export class BookingProvider {
  constructor(private http: HttpClient) {}

  GetBookingById(id: string): Observable<ProductBooking> {
    const url = `${ApiEndpoints.Bookings}/${id}`;

    return this.http.get<ProductBooking>(url);
  }

  GetBookingsDashboard(filters: GridLoadOptions<BookingsFilters>): Promise<GridPageData<BookingInterface>> {
    const url = ApiEndpoints.BookingsAdmin;

    return this.http
      .post<{ bookings: GridPageData<BookingInterface> }>(url, filters)
      .toPromise()
      .then((x) => x.bookings);
  }

  DownloadBookingsCSV(filters: GridLoadOptions<BookingsFilters>): Observable<BlobPart> {
    const url = `${ApiEndpoints.BookingsAdmin}/export-csv`;
    const headers = new HttpHeaders().set('Accept', ContentType.CSV);

    return this.http.post<BlobPart>(url, { ...filters }, { headers, responseType: 'text' as any });
  }

  validateTicket(ticketIdentifier: string): Observable<{ isSuccess: boolean; message: string }> {
    const url = `${ApiEndpoints.BookingsAdmin}/validate-ticket?ticketIdentifier=${ticketIdentifier}`;

    return this.http.get<{ isSuccess: boolean; message: string }>(url);
  }

  Approve(bookingId: number): Promise<{ succeeded: boolean; message: string; isAlreadyApproved: boolean }> {
    const url = `${ApiEndpoints.BookingsAdmin}/approve`;

    return this.http
      .post<{ succeeded: boolean; message: string; isAlreadyApproved: boolean }>(url, { bookingId })
      .toPromise();
  }

  Cancel(bookingId: number): Promise<{ succeeded: boolean; message: string; isAlreadyCanceled: boolean }> {
    const url = `${ApiEndpoints.BookingsAdmin}/cancel`;

    return this.http
      .post<{ succeeded: boolean; message: string; isAlreadyCanceled: boolean }>(url, { bookingId })
      .toPromise();
  }

  deny(bookingId: number, reasonOfDenial: string, alternative: string): Promise<any> {
    const url = `${ApiEndpoints.BookingsAdmin}/deny`;

    return this.http.post<any>(url, { bookingId, reasonOfDenial, alternative }).toPromise();
  }

  GetBookings(params: {
    productId?: any;
    from?: string;
    to?: string;
    reference?: string;
    productName?: string;
    includeCancelled?: string;
    page?: string;
    pageSize?: string;
    withoutInvoices?: string;
    hasSucceededPayment?: string;
  }): Observable<{ bookings: ProductBooking[]; totalCount: number }> {
    for (const propName in params) {
      if (params[propName] === null || params[propName] === undefined || params[propName] === 'undefined') {
        delete params[propName];
      }
    }

    const url = ApiEndpoints.Bookings;
    return this.http.get(url, { params: params, observe: 'response' }).pipe(
      map((res: any) => {
        return { bookings: res.body, totalCount: res.headers.get('total-rows-count') };
      })
    );
  }

  Add(booking: Booking): Observable<any> {
    const url = ApiEndpoints.Bookings;

    return this.http.post<Booking>(url, booking);
  }

  Edit(booking: Booking): Observable<any> {
    const url = ApiEndpoints.Bookings;

    return this.http.put<Booking>(url, booking);
  }

  Refund(
    id: number,
    refundAmount: number,
    refundReason: string,
    refundComment: string,
    refundCurrency: string
  ): Observable<any> {
    const intent = {
      bookingId: id,
      refundAmount: refundAmount,
      refundCurrency: refundCurrency,
      refundReason: refundReason,
      refundComment: refundComment
    };

    const url = `${ApiEndpoints.Bookings}/cancel`;

    return this.http.post<any>(url, intent);
  }

  Reject(bookingId: number, reason: string): Observable<any> {
    const url = `${ApiEndpoints.Bookings}/reject`;

    return this.http.post<any>(url, { bookingId: bookingId, reason: reason });
  }

  GetProductsByPartner(partnerId: number): Observable<GetPartnerProductsResponse> {
    let params = {};
    params['partnerId'] = partnerId;
    const url = `${ApiEndpoints.BookingsAdmin}/partner-products`;

    return this.http.get<GetPartnerProductsResponse>(url, { params });
  }
}

export interface PartnerProduct {
  id: number;
  typeId: ProductTypeId;
  name: string;
  status: ProductStatusInt;
  location: {
    countryName: string;
    regionName: string;
  };
  options: PartnerProductOption[];
  useRealTimeAvailability: boolean;
  currency: string;
  isPrivate: boolean;
}
export interface PartnerProductOption {
  id: number;
  name: string;
  pricingType: PricingType;
  pricingSchemas: {
    minimumCapacity: number;
    count?: number;
  }[];
  groupRate: number;
  minGroupCapacity: number;
  maxGroupCapacity: number;
  isMultiDay: boolean;
  languages: string[];
}
export interface GetPartnerProductsResponse {
  products: PartnerProduct[];
}
