import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageAdapter {
  private get storage(): Storage {
    return localStorage;
  }

  public GetItem(key: string): string {
    return this.storage.getItem(key);
  }

  public SetItem(key: string, data: string): void {
    if (data) this.storage.setItem(key, data);
  }

  public RemoveItem(key: string): void {
    if (this.GetItem(key)) this.storage.removeItem(key);
  }

  public Clear(): void {
    this.storage.clear();
  }
}
