import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { ApiEndpoints } from '../infrastructure/application.config';
import { Bespoke } from '../models/booking/bespoke.model';

@Injectable()
export class EnquiryProvider {
  constructor(private http: HttpClient) {}

  submitEnquiry(bespoke: Bespoke): Observable<Bespoke> {
    const url = ApiEndpoints.Enquiries;

    return this.http.post<Bespoke>(url, bespoke);
  }

  getEnquiries(): Observable<Bespoke[]> {
    const url = ApiEndpoints.Enquiries;

    return this.http.get<Bespoke[]>(url);
  }
}
