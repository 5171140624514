import { Injectable, Inject, InjectionToken, ErrorHandler } from '@angular/core';
import * as Rollbar from 'rollbar';

import { rollbarConfig } from '../infrastructure/rollbar.config';

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err: any): void {
    if (err instanceof UnreportableError) {
      console.log('Not reporting UnreportableError', err);
      return;
    }

    console.error(err);
    this.rollbar.error(err.originalError || err);
  }
}

export class UnreportableError extends Error {
  // As per recommendation in TypeScript docs:
  // https://github.com/Microsoft/TypeScript-wiki/blob/main/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
  constructor(message: string) {
    super(message);

    Object.setPrototypeOf(this, UnreportableError.prototype);
  }
}
