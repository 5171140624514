import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ErrorProvider {
  constructor(private http: HttpClient) {}

  get404Error() {
    return this.http.get('https://jsonplaceholder.typicode.com/wai+dai=404');
  }

  tryPost() {
    return this.http.post('https://httpstat.us/400', {});
  }
}
