import { Injectable } from '@angular/core';
import { SimpleItem } from '../models/account/simple-item.model';
import { PricingScheme } from '../models/product/option/pricing-scheme.model';

@Injectable()
export class NomenclatureService {
  get currencies(): SimpleItem[] {
    return [
      { id: 'AUD', name: 'AUD' },
      { id: 'CAD', name: 'CAD' },
      { id: 'EUR', name: 'EUR' },
      { id: 'GBP', name: 'GBP' },
      { id: 'NZD', name: 'NZD' },
      { id: 'USD', name: 'USD' },
      { id: 'ZAR', name: 'ZAR' },
      { id: 'ARS', name: 'ARS' },
      { id: 'UYU', name: 'UYU' },
      { id: 'CLP', name: 'CLP' },
      { id: 'MDL', name: 'MDL' }
    ];
  }

  get weekDays() {
    return [
      { id: 'MO', name: 'M', selected: false },
      { id: 'TU', name: 'T', selected: false },
      { id: 'WE', name: 'W', selected: false },
      { id: 'TH', name: 'T', selected: false },
      { id: 'FR', name: 'F', selected: false },
      { id: 'SA', name: 'S', selected: false },
      { id: 'SU', name: 'S', selected: false }
    ];
  }

  get getTimes() {
    return [
        { text: '07:00', value: '07:00:00' },
        { text: '07:15', value: '07:15:00' },
        { text: '07:30', value: '07:30:00' },
        { text: '07:45', value: '07:45:00' },
        { text: '08:00', value: '08:00:00' },
        { text: '08:15', value: '08:15:00' },
        { text: '08:30', value: '08:30:00' },
        { text: '08:45', value: '08:45:00' },
        { text: '09:00', value: '09:00:00' },
        { text: '09:15', value: '09:15:00' },
        { text: '09:30', value: '09:30:00' },
        { text: '09:45', value: '09:45:00' },
        { text: '10:00', value: '10:00:00' },
        { text: '10:15', value: '10:15:00' },
        { text: '10:30', value: '10:30:00' },
        { text: '10:45', value: '10:45:00' },
        { text: '11:00', value: '11:00:00' },
        { text: '11:15', value: '11:15:00' },
        { text: '11:30', value: '11:30:00' },
        { text: '11:45', value: '11:45:00' },
        { text: '12:00', value: '12:00:00' },
        { text: '12:15', value: '12:15:00' },
        { text: '12:30', value: '12:30:00' },
        { text: '12:45', value: '12:45:00' },
        { text: '13:00', value: '13:00:00' },
        { text: '13:15', value: '13:15:00' },
        { text: '13:30', value: '13:30:00' },
        { text: '13:45', value: '13:45:00' },
        { text: '14:00', value: '14:00:00' },
        { text: '14:15', value: '14:15:00' },
        { text: '14:30', value: '14:30:00' },
        { text: '14:45', value: '14:45:00' },
        { text: '15:00', value: '15:00:00' },
        { text: '15:15', value: '15:15:00' },
        { text: '15:30', value: '15:30:00' },
        { text: '15:45', value: '15:45:00' },
        { text: '16:00', value: '16:00:00' },
        { text: '16:15', value: '16:15:00' },
        { text: '16:30', value: '16:30:00' },
        { text: '16:45', value: '16:45:00' },
        { text: '17:00', value: '17:00:00' },
        { text: '17:15', value: '17:15:00' },
        { text: '17:30', value: '17:30:00' },
        { text: '17:45', value: '17:45:00' },
        { text: '18:00', value: '18:00:00' },
        { text: '18:15', value: '18:15:00' },
        { text: '18:30', value: '18:30:00' },
        { text: '18:45', value: '18:45:00' },
        { text: '19:00', value: '19:00:00' },
        { text: '19:15', value: '19:15:00' },
        { text: '19:30', value: '19:30:00' },
        { text: '19:45', value: '19:45:00' },
        { text: '20:00', value: '20:00:00' },
        { text: '20:15', value: '20:15:00' },
        { text: '20:30', value: '20:30:00' },
        { text: '20:45', value: '20:45:00' },
        { text: '21:00', value: '21:00:00' },
        { text: '21:15', value: '21:15:00' },
        { text: '21:30', value: '21:30:00' },
        { text: '21:45', value: '21:45:00' },
        { text: '22:00', value: '22:00:00' },
        { text: '22:15', value: '22:15:00' },
        { text: '22:30', value: '22:30:00' },
        { text: '22:45', value: '22:45:00' },
        { text: '23:00', value: '23:00:00' },
        { text: '23:15', value: '23:15:00' },
        { text: '23:30', value: '23:30:00' },
        { text: '23:45', value: '23:45:00' },
        { text: '00:00', value: '00:00:00' },
        { text: '00:15', value: '00:15:00' },
        { text: '00:30', value: '00:30:00' },
        { text: '00:45', value: '00:45:00' },
        { text: '01:00', value: '01:00:00' },
        { text: '01:15', value: '01:15:00' },
        { text: '01:30', value: '01:30:00' },
        { text: '01:45', value: '01:45:00' },
        { text: '02:00', value: '02:00:00' },
        { text: '02:15', value: '02:15:00' },
        { text: '02:30', value: '02:30:00' },
        { text: '02:45', value: '02:45:00' },
        { text: '03:00', value: '03:00:00' },
        { text: '03:15', value: '03:15:00' },
        { text: '03:30', value: '03:30:00' },
        { text: '03:45', value: '03:45:00' },
        { text: '04:00', value: '04:00:00' },
        { text: '04:15', value: '04:15:00' },
        { text: '04:30', value: '04:30:00' },
        { text: '04:45', value: '04:45:00' },
        { text: '05:00', value: '05:00:00' },
        { text: '05:15', value: '05:15:00' },
        { text: '05:30', value: '05:30:00' },
        { text: '05:45', value: '05:45:00' },
        { text: '06:00', value: '06:00:00' },
        { text: '06:15', value: '06:15:00' },
        { text: '06:30', value: '06:30:00' },
        { text: '06:45', value: '06:45:00' }
    ];
  }

  get getSortedTimes() {
    return [
      { text: '00:30', value: '00:30:00' },
      { text: '01:00', value: '01:00:00' },
      { text: '01:30', value: '01:30:00' },
      { text: '02:00', value: '02:00:00' },
      { text: '02:30', value: '02:30:00' },
      { text: '03:00', value: '03:00:00' },
      { text: '03:30', value: '03:30:00' },
      { text: '04:00', value: '04:00:00' },
      { text: '04:30', value: '04:30:00' },
      { text: '05:00', value: '05:00:00' },
      { text: '05:30', value: '05:30:00' },
      { text: '06:00', value: '06:00:00' },
      { text: '06:30', value: '06:30:00' },
      { text: '07:00', value: '07:00:00' },
      { text: '07:30', value: '07:30:00' },
      { text: '08:00', value: '08:00:00' },
      { text: '08:30', value: '08:30:00' },
      { text: '09:00', value: '09:00:00' },
      { text: '09:30', value: '09:30:00' },
      { text: '10:00', value: '10:00:00' },
      { text: '10:30', value: '10:30:00' },
      { text: '11:00', value: '11:00:00' },
      { text: '11:30', value: '11:30:00' },
      { text: '12:00', value: '12:00:00' },
      { text: '12:30', value: '12:30:00' },
      { text: '13:00', value: '13:00:00' },
      { text: '13:30', value: '13:30:00' },
      { text: '14:00', value: '14:00:00' },
      { text: '14:30', value: '14:30:00' },
      { text: '15:00', value: '15:00:00' },
      { text: '15:30', value: '15:30:00' },
      { text: '16:00', value: '16:00:00' },
      { text: '16:30', value: '16:30:00' },
      { text: '17:00', value: '17:00:00' },
      { text: '17:30', value: '17:30:00' },
      { text: '18:00', value: '18:00:00' },
      { text: '18:30', value: '18:30:00' },
      { text: '19:00', value: '19:00:00' },
      { text: '19:30', value: '19:30:00' },
      { text: '20:00', value: '20:00:00' },
      { text: '20:30', value: '20:30:00' },
      { text: '21:00', value: '21:00:00' },
      { text: '21:30', value: '21:30:00' },
      { text: '22:00', value: '22:00:00' },
      { text: '22:30', value: '22:30:00' },
      { text: '23:00', value: '23:00:00' },
      { text: '23:30', value: '23:30:00' },
      { text: '24:00', value: '24:00:00' },
      { text: '24:30', value: '24:30:00' },
      { text: '25:00', value: '25:00:00' },
      { text: '25:30', value: '25:30:00' },
      { text: '26:00', value: '26:00:00' },
      { text: '26:30', value: '26:30:00' },
      { text: '27:00', value: '27:00:00' },
      { text: '27:30', value: '27:30:00' },
      { text: '28:00', value: '28:00:00' },
      { text: '28:30', value: '28:30:00' },
      { text: '29:00', value: '29:00:00' },
      { text: '29:30', value: '29:30:00' },
      { text: '30:00', value: '30:00:00' },
      { text: '30:30', value: '30:30:00' },
      { text: '31:00', value: '31:00:00' },
      { text: '31:30', value: '31:30:00' },
      { text: '32:00', value: '32:00:00' },
      { text: '32:30', value: '32:30:00' },
      { text: '33:00', value: '33:00:00' },
      { text: '33:30', value: '33:30:00' },
      { text: '34:00', value: '34:00:00' },
      { text: '34:30', value: '34:30:00' },
      { text: '35:00', value: '35:00:00' },
      { text: '35:30', value: '35:30:00' },
      { text: '36:00', value: '36:00:00' },
      { text: '36:30', value: '36:30:00' },
      { text: '37:00', value: '37:00:00' },
      { text: '37:30', value: '37:30:00' },
      { text: '38:00', value: '38:00:00' },
      { text: '38:30', value: '38:30:00' },
      { text: '39:00', value: '39:00:00' },
      { text: '39:30', value: '39:30:00' },
      { text: '40:00', value: '40:00:00' }
    ];
  }

    get getExperienceTimes() {
        return [
            { text: '00:30', value: '00:30:00' },
            { text: '01:00', value: '01:00:00' },
            { text: '01:30', value: '01:30:00' },
            { text: '02:00', value: '02:00:00' },
            { text: '02:30', value: '02:30:00' },
            { text: '03:00', value: '03:00:00' },
            { text: '03:30', value: '03:30:00' },
            { text: '04:00', value: '04:00:00' },
            { text: '04:30', value: '04:30:00' },
            { text: '05:00', value: '05:00:00' },
            { text: '05:30', value: '05:30:00' },
            { text: '06:00', value: '06:00:00' },
            { text: '06:30', value: '06:30:00' },
            { text: '07:00', value: '07:00:00' },
            { text: '07:30', value: '07:30:00' },
            { text: '08:00', value: '08:00:00' },
            { text: '08:30', value: '08:30:00' },
            { text: '09:00', value: '09:00:00' },
            { text: '09:30', value: '09:30:00' },
            { text: '10:00', value: '10:00:00' },
            { text: '10:30', value: '10:30:00' },
            { text: '11:00', value: '11:00:00' },
            { text: '11:30', value: '11:30:00' },
            { text: '12:00', value: '12:00:00' },

        ];
    }

  get hotelPricingShemas(): PricingScheme[] {
    return [
      {
        pricingSchemaId: 1,
        name: 'Child',
        maximumCapacity: 0,
        minimumCapacity: 0,
        rate: 0
      },
      {
        pricingSchemaId: 3,
        name: 'Adult',
        maximumCapacity: 0,
        minimumCapacity: 0,
        rate: 0
      },
      {
        pricingSchemaId: 5,
        name: 'Extra Beds',
        maximumCapacity: 0,
        minimumCapacity: 0,
        rate: 0
      }
    ];
  }

  get pricingShemas(): PricingScheme[] {
    return [
      {
        pricingSchemaId: 1,
        name: 'Child',
        maximumCapacity: 0,
        minimumCapacity: 0,
        rate: 0
      },
      {
        pricingSchemaId: 2,
        name: 'Student',
        maximumCapacity: 0,
        minimumCapacity: 0,
        rate: 0
      },
      {
        pricingSchemaId: 3,
        name: 'Adult',
        maximumCapacity: 0,
        minimumCapacity: 0,
        rate: 0
      },
      {
        pricingSchemaId: 4,
        name: 'Senior',
        maximumCapacity: 0,
        minimumCapacity: 0,
        rate: 0
      }
    ];
  }
}
