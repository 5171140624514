import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { AppRoutes } from './app.route';
import { CoreModule } from './core/core.module';
import { RollbarErrorHandler, rollbarFactory, RollbarService } from './core/services/rollbar.service';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './pages/home/home.component';

@NgModule({
  declarations: [AppComponent, HomeComponent],
  imports: [CommonModule, BrowserModule, CoreModule, FormsModule, RouterModule.forRoot(AppRoutes)],
  providers: [
    {
      provide: ErrorHandler,
      useClass: RollbarErrorHandler
    },
    {
      provide: RollbarService,
      useFactory: rollbarFactory
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
