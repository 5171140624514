import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

import { ApiEndpoints } from 'src/app/core/infrastructure/application.config';
import { Location } from 'src/app/core/models/location/location.model';
import { LocationImage } from '../models/location/location-image.model';
import { LocationMetaData } from '../models/location/location-meta-data.model';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Coordinates } from '../models/location/coordinates.model';
import axios from 'axios';

export type AdsLandingPageType = 'city-ads' | 'region-ads' | 'country-ads';
export type LandingPageType = 'city' | 'region' | 'country';

@Injectable()
export class LocationProvider {
  constructor(private http: HttpClient) {}

  getCitiesByCountryCache: Record<number, Location[]> = {};

  getCitiesByCountry(countryId: number): Observable<Location[]> {
    if (this.getCitiesByCountryCache[countryId]) {
      return of(this.getCitiesByCountryCache[countryId]);
    }

    const url = `${ApiEndpoints.Location}/countries/${countryId}/cities`;

    return this.http.get<Location[]>(url).pipe(tap((response) => (this.getCitiesByCountryCache[countryId] = response)));
  }

  getRegionsByCountryCache: Record<number, Location[]> = {};

  getRegionsByCountry(countryId: number, force: boolean = false): Observable<Location[]> {
    if (this.getRegionsByCountryCache[countryId] && !force) {
      return of(this.getRegionsByCountryCache[countryId]);
    }

    const url = `${ApiEndpoints.Location}/countries/${countryId}/regions`;

    return this.http
      .get<Location[]>(url)
      .pipe(tap((response) => (this.getRegionsByCountryCache[countryId] = response)));
  }

  async addRegion(regionName: string, countryId: number): Promise<void> {
    const url = `${ApiEndpoints.LocationAdmin}/add-region`;
    await this.http.post(url, { countryId, regionName }).toPromise();
  }

  getLocationMetaData(type: AdsLandingPageType | LandingPageType, locationId: number): Observable<Location> {
    let params = {};
    params = { locationId: locationId, includeDisabledMetadata: true, ...params };
    const url = `${ApiEndpoints.LocationAdmin}/landing-edit-${type}`;
    return this.http.get<Location>(url, { params });
  }

  uploadLocationImage(type: string, id: number, file: any): Observable<LocationImage> {
    const formData = new FormData();
    formData.append('file', file);
    const url = `${ApiEndpoints.LocationAdmin}/${type}/${id}/image`;

    return this.http.post<LocationImage>(url, formData);
  }

  updateLocationMetaData(id: number, metaData: LocationMetaData, type: string): Observable<any> {
    const url = `${ApiEndpoints.LocationAdmin}/${type}/${id}/metadata`;

    return this.http.put<any>(url, metaData);
  }

  async getTimeZoneInfoByCoordinates(coordinates: Coordinates) {
    const timeStamp = Math.floor(Date.now() / 1000);
    const url = `https://maps.googleapis.com/maps/api/timezone/json?location=${coordinates.latitude},${coordinates.longitude}&timestamp=${timeStamp}&key=AIzaSyCDbgLyZpmEHMFoyNI5nyR7ffASNglsfSY`;

    return await axios
      .get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
