export enum ProductTypeId {
  Hotel = 1,
  Winery = 2,
  Tour = 3,
  Event = 4
}

export enum ProductTypeName {
  Hotel = 'hotel',
  Winery = 'winery',
  Tour = 'tour',
  Event = 'event'
}

export enum ProductTypeAvailabilitySystemId {
  WineriestGoHotelId = 1,
  WineriestGoWeneryId = 2,
  WineriestGoTourId = 3,
  TourBizTourId = 6,
  FareHarborTourId = 7,
  FareHarborWineryId = 8
}
