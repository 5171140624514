import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ErrorProvider } from './providers/error.provider';
import { GlobalErrorHandler } from './global-error.handler';
import { AccountProvider } from './providers/account.provider';
import { NotifyService } from './services/notify.service';
import { UserProvider } from './providers/user.provider';
import { ProductProvider } from './providers/product.provider';
import { LocationProvider } from './providers/location.provider';
import { Authorize } from './guards/authorize.guard';
import { AuthenticationContext } from './infrastructure/authentication.context';
import { LocalStorageAdapter } from './infrastructure/local-storage.adapter';
import { AppHttpInterceptor } from './interceptors/http.interceptor';
import { NomenclatureService } from './services/nomenclature.service';
import { SchedulerService } from './services/scheduler.service';
import { PreviousRouteService } from './services/previous-route.service';
import { BookingProvider } from './providers/booking.provider';
import { PartnerProvider } from './providers/partner.provider';
import { ReportingProvider } from './providers/reporting.provider';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { InvoicingProvider } from './providers/invoicing.provider';
import { EnquiryProvider } from './providers/enquiry.provider';
import { FareharborProvider } from './providers/fareharbor.provider';
import { TicketProvider } from './providers/ticket.provider';
import { GiftCardProvider } from './providers/gift-cards.provider';

@NgModule({
  imports: [CommonModule, BrowserAnimationsModule, HttpClientModule, ToastrModule.forRoot()],
  providers: [
    ErrorProvider,
    AccountProvider,
    UserProvider,
    TicketProvider,
    ProductProvider,
    FareharborProvider,
    ProductProvider,
    BookingProvider,
    GiftCardProvider,
    NotifyService,
    SchedulerService,
    NomenclatureService,
    PreviousRouteService,
    LocationProvider,
    PartnerProvider,
    ReportingProvider,
    EnquiryProvider,
    LocalStorageAdapter,
    InvoicingProvider,
    AuthenticationContext,
    Authorize,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true }
  ]
})
export class CoreModule {}
