import * as Rollbar from 'rollbar';
import { environment } from 'src/environments/environment';
import { GIT_REVISION } from 'src/environments/version';

let liveEnvironment: string;

if (environment.isProduction) {
  liveEnvironment = 'production';
} else if (environment.isStaging) {
  liveEnvironment = 'staging';
} else {
  liveEnvironment = 'development';
}

export const rollbarConfig: Rollbar.Configuration = {
  accessToken: '104dfd1f5f0849408e2e547e70d49582',
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: liveEnvironment,
  codeVersion: GIT_REVISION,
  enabled: environment.isProduction || environment.isStaging
};
