import { ProductBooking } from '../product/product-booking.model';
import { BookedOption } from '../product/option/product-option-booked-options.model';
import { ProductAvailability } from '../product/product-availability.model';

export class CellBooking {
  cellIndexes: number[];
  colSpan: number;
  bookingFrom: string;
  bookingTo: string;
  booking: ProductBooking;
  bookedOption: BookedOption;
  availability: ProductAvailability;
}
