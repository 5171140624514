import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { map } from 'rxjs/internal/operators/map';
import { Subject } from 'rxjs/internal/Subject';
import { Observable } from 'rxjs/Observable';
import { ProductProvider } from 'src/app/core/providers/product.provider';
import { BookingProvider } from 'src/app/core/providers/booking.provider';
import { ProductBooking } from 'src/app/core/models/product/product-booking.model';
import { Product } from 'src/app/core/models/product/product.model';
import { ProductType } from 'src/app/core/models/productType/product-type.model';
import { ProductOptionAvailability } from 'src/app/core/models/product/option/product-option-availability.model';
import { ProductAggregatedAvailability } from 'src/app/core/models/product/product-aggregated-availability.model';
import { ProductAvailability } from 'src/app/core/models/product/product-availability.model';
import { Header } from '../models/dashboard-scheduler/header.model';
import { DashboardOption } from '../models/dashboard-scheduler/option.model';
import { DashboardRow } from '../models/dashboard-scheduler/row.model';
import { DashboardCell } from '../models/dashboard-scheduler/cell.model';
import { CellBooking } from '../models/dashboard-scheduler/cell-booking.model';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SchedulerService {
  product: Product;
  selectedDate: Date;
  productType: ProductType;
  productAvailability: ProductOptionAvailability[] = [];
  productAggregatedAvailability: ProductAggregatedAvailability[] = [];
  productBookings: ProductBooking[];
  needDataUpdate: boolean;
  firstDayOfMonth: Date;
  firstDayOfWeek: Date;
  firstDayOfDisplayedMonth: Date;
  lastDayOfMonth: Date;
  lastDayOfWeek: Date;
  lastDayOfDisplayedMonth: Date;

  private _currentDate = new Subject<{ selectedDate: Date }>();
  private changeTableBookingData = new Subject<{
    bookingTableData: any;
    productBookings: ProductBooking[];
    dashboardAvailability: ProductAvailability[];
  }>();
  private changeAvailabilityData = new Subject<{ availability: ProductAggregatedAvailability[] }>();

  constructor(private readonly _productProvider: ProductProvider, private readonly _bookingProvider: BookingProvider) {
    this.SelectedDate = new Date();
  }

  set Product(product: Product) {
    this.product = product;
  }

  set SelectedDate(date: Date) {
    this.needDataUpdate = !this.selectedDate;
    this.selectedDate = date;

    if (
      this.selectedDate &&
      (!moment(this.selectedDate).isBetween(this.firstDayOfDisplayedMonth, this.lastDayOfDisplayedMonth) ||
        this.selectedDate < this.firstDayOfDisplayedMonth ||
        this.selectedDate > this.lastDayOfDisplayedMonth)
    ) {
      this.firstDayOfMonth = new Date(moment(this.selectedDate).startOf('month').toDate());
      this.firstDayOfDisplayedMonth = new Date(moment(this.firstDayOfMonth).startOf('week').toDate());

      this.lastDayOfMonth = new Date(moment(this.selectedDate).endOf('month').toDate());
      this.lastDayOfDisplayedMonth = new Date(moment(this.lastDayOfMonth).endOf('week').toDate());
      this.needDataUpdate = true;
    } else {
      this.needDataUpdate = false;
    }

    this.firstDayOfWeek = new Date(moment(this.selectedDate).startOf('week').toDate());
    this.lastDayOfWeek = new Date(moment(this.selectedDate).endOf('week').toDate());
    this._currentDate.next({ selectedDate: this.selectedDate });
  }

  get currentDate(): Observable<{ selectedDate: Date }> {
    return this._currentDate.asObservable();
  }

  get aggregatedAvailabilityData(): Observable<{ availability: ProductAggregatedAvailability[] }> {
    return this.changeAvailabilityData.asObservable();
  }

  get bookings(): Observable<{
    bookingTableData: any;
    productBookings: ProductBooking[];
    dashboardAvailability: ProductAvailability[];
  }> {
    return this.changeTableBookingData.asObservable();
  }

  async updateTableBookingData(product?: Product, productType?: ProductType, needDataUpdate?: boolean): Promise<void> {
    if (product) this.product = product;

    if (productType) this.productType = productType;

    if (this.selectedDate && (this.needDataUpdate || needDataUpdate)) {
      const params = {
        productId: this.product.id,
        from: moment(this.firstDayOfDisplayedMonth).format('YYYY-MM-DDTHH:mm:ss'),
        to: moment(this.lastDayOfDisplayedMonth).format('YYYY-MM-DDTHH:mm:ss')
      };
      await forkJoin(of([]), this._bookingProvider.GetBookings(params))
        .pipe(
          map(([productAggregatedAvailability, productBookings]) => {
            this.needDataUpdate = false;
            this.productAvailability = [];
            this.productAggregatedAvailability = productAggregatedAvailability;
            this.productBookings = productBookings.bookings;
            this.changeAvailabilityData.next({ availability: this.productAggregatedAvailability });
            if (this.product)
              this.changeTableBookingData.next({
                bookingTableData: this.mapTableBookings(
                  this.product,
                  this.productType,
                  this.productAvailability,
                  this.productBookings
                ),
                productBookings: this.productBookings,
                dashboardAvailability: this.productAvailability
              });
          })
        )
        .toPromise();
    } else {
      this.changeAvailabilityData.next({ availability: this.productAggregatedAvailability });
      this.changeTableBookingData.next({
        bookingTableData: this.mapTableBookings(
          this.product,
          this.productType,
          this.productAvailability,
          this.productBookings
        ),
        productBookings: this.productBookings,
        dashboardAvailability: this.productAvailability
      });
    }
  }

  updateAvailability(selectedDate?: Date, allProducts?: boolean) {
    const date = !selectedDate ? (this.selectedDate ? this.selectedDate : new Date()) : selectedDate;
    const firstDayOfMonth = new Date(moment(date).startOf('month').toDate());
    const firstDayOfDisplayedMonth = new Date(moment(firstDayOfMonth).startOf('week').toDate());
    const lastDayOfMonth = new Date(moment(date).endOf('month').toDate());
    const lastDayOfDisplayedMonth = new Date(
      moment(lastDayOfMonth).endOf('week').add(1, 'days').endOf('week').toDate()
    );

    of([]).subscribe(
      (productAggregatedAvailability: ProductAggregatedAvailability[]) => {
        this.productAvailability = [];
        this.productAggregatedAvailability = productAggregatedAvailability;
        this.changeAvailabilityData.next({ availability: this.productAggregatedAvailability });
      },
      (error) => {
        this.changeAvailabilityData.next(null);
      }
    );
  }

  mapTableBookings(
    product: Product,
    productType: ProductType,
    dashboardAvailability: ProductAvailability[],
    productBookings: ProductBooking[]
  ) {
    const headers: Header[] = [
      { date: undefined, text: productType.optionTitle },
      { date: undefined, text: '' }
    ];

    const options = [];
    // Create header dates
    for (let i = 0; i <= 6; i++)
      headers.push({
        date: moment(this.firstDayOfWeek).add(i, 'days'),
        text: moment(this.firstDayOfWeek).add(i, 'days').format('DD MMM')
      });

    product.options.forEach((option) => {
      // Create new option line
      const _option: DashboardOption = { option: option, availabilities: [] };

      // Create option availability line
      if (option.availability.length) {
        option.availability.forEach((avail) => {
          //  Define availability row
          let availbilityText;

          if (avail.startTime || avail.endTime)
            availbilityText = `${avail.startTime ? moment(avail.startTime, 'HH:mm:ss').format('HH:mm') + ' - ' : ''}${
              avail.endTime ? moment(avail.endTime, 'HH:mm:ss').format('HH:mm') : ''
            } `;
          else availbilityText = 'Full day';

          const row: DashboardRow = {
            availbilityText: availbilityText,
            startTime: avail.startTime,
            endTime: avail.endTime,
            cells: [],
            availabilityRuleId: avail.id
          };

          // Filter and push availabilities by header dates
          headers.forEach((header) => {
            let cell: DashboardCell;

            if (header.date) {
              if (
                dashboardAvailability.find((a) => {
                  if (
                    moment(a.day).isSame(header.date) &&
                    a.optionId == option.id &&
                    avail.id == a.availabilityRuleId
                  ) {
                    return (
                      moment(a.day).isSame(header.date) && a.optionId == option.id && avail.id == a.availabilityRuleId
                    );
                  }
                })
              ) {
                const availability = dashboardAvailability.find(
                  (a) =>
                    moment(a.day).isSame(header.date) && a.optionId == option.id && avail.id == a.availabilityRuleId
                );
                cell = {
                  day: header.date.format('YYYY-MM-DD'),
                  text: '',
                  //  || availability.price.toString(),
                  isAvailability: true,
                  isBooking: false,
                  availability: availability,
                  generalColspan: 1
                };
              } else {
                cell = {
                  day: header.date.format('YYYY-MM-DD'),
                  text: 'No availability',
                  isAvailability: false,
                  isBooking: false,
                  generalColspan: 1
                };
              }
              row.cells.push(cell);
            }
          });

          _option.availabilities.push(row);
        });
      } else {
        //  Define empty availability row
        const row: DashboardRow = { availbilityText: 'No availability', cells: [] };

        headers.forEach((header) => {
          if (header.date)
            row.cells.push({
              day: header.date.format('YYYY-MM-DD'),
              text: 'No availability',
              isAvailability: false,
              isBooking: false,
              generalColspan: 1
            });
        });
        _option.availabilities.push(row);
      }

      // Filter and push bookings by header dates
      const cellBookings: CellBooking[] = [];
      const _headers = headers.filter((header) => header.date);
      _headers.forEach((header) => {
        productBookings.forEach((_booking) => {
          _booking.bookedOptions.forEach((bookedOption) => {
            const headerDate = moment(header.date).format('YYYY-MM-DD');
            const cellBooking = new CellBooking();

            cellBooking.bookingFrom = moment(bookedOption.from).format('YYYY-MM-DD');
            cellBooking.bookingTo = moment(bookedOption.to).format('YYYY-MM-DD');

            if (
              !cellBookings.find((cell: CellBooking) => cell.bookedOption == bookedOption) &&
              headerDate <= cellBooking.bookingTo &&
              headerDate >= cellBooking.bookingFrom
            ) {
              cellBooking.booking = _booking;
              cellBooking.bookedOption = bookedOption;
              // cellBooking.availability = dashboardAvailability.find(avail => bookedOption.dailyRates[0].availabilityRuleId == avail.availabilityRuleId && bookedOption.from == avail.startDate);
              cellBookings.push(cellBooking);
            }
          });
        });
      });

      // Find related cells for booking
      // _option.availabilities.forEach(availability => {
      //     let _cells = cellBookings.filter(cell => { if (availability.availabilityRuleId == cell.bookedOption.dailyRates[0].availabilityRuleId) return cell });
      //     _cells.forEach(cell => {
      //         cell.cellIndexes = [];
      //         const dates = [];
      //         const startDate = moment(cell.bookingFrom).startOf('day');
      //         const endDate = moment(cell.bookingTo).startOf('day');
      //         while (startDate <= endDate) {
      //             dates.push(startDate.clone().toDate());
      //             startDate.add(1, 'days');
      //         };
      //         dates.forEach(date => {
      //             const bookingCellHeader = _headers.findIndex(_header => moment(_header.date).format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD'));
      //             if (bookingCellHeader != -1) cell.cellIndexes.push(bookingCellHeader);
      //         });
      //         cell.colSpan = cell.cellIndexes.length;
      //     });

      //     _headers.forEach((header, index) => {
      //         const dayBookings = _cells.filter(booking => booking.cellIndexes.includes(index));

      //         const cellItems: any = _cells.filter(itemData => dayBookings.some(item => lodash.intersection(itemData.cellIndexes, item.cellIndexes).length));
      //         cellItems.forEach(booking => {
      //             _cells.forEach(b => {
      //                 if (lodash.intersection(booking.cellIndexes, b.cellIndexes).length && !cellItems.includes(b))
      //                     cellItems.push(b);
      //             });
      //         });
      //         if (cellItems.length) {
      //             _cells = lodash.difference(_cells, cellItems);
      //             const groupCellIndexes = lodash.uniq(lodash.concat(...lodash.map(cellItems, 'cellIndexes')));
      //             const startIndex = availability.cells.findIndex(cell => cell.day && moment(header.date).format('YYYY-MM-DD') == moment(cell.day).format('YYYY-MM-DD'));
      //             availability.cells[startIndex] = { generalColSpan: groupCellIndexes.length, bookings: cellItems, groupCellIndexes: groupCellIndexes, isBooking: true };
      //             availability.cells[startIndex].from = new Date(_headers[groupCellIndexes[0]].date.startOf('day').toDate()).getTime();
      //             availability.cells[startIndex].to = new Date(_headers[groupCellIndexes[groupCellIndexes.length - 1]].date.startOf('day').toDate()).getTime();
      //             if (groupCellIndexes.length > 1) {
      //                 availability.cells.splice(startIndex + 1, groupCellIndexes.length - 1);
      //             }
      //         }
      //     });
      // });
      options.push(_option);
    });

    return { headers: headers, options: options };
  }
}
