import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

import { ApiEndpoints } from 'src/app/core/infrastructure/application.config';
import { Invoice } from '../models/invoice/invoice.model';

@Injectable()
export class InvoicingProvider {
  constructor(private http: HttpClient) {}

  GetInvoices(): Observable<Invoice[]> {
    const url = ApiEndpoints.Invoices;

    return this.http.get<Invoice[]>(url);
  }

  CreateInvoice(invoice: Invoice): Observable<Invoice> {
    const url = ApiEndpoints.Invoices;
    return this.http.post<Invoice>(url, invoice);
  }

  CompleteInvoice(id: number): Observable<Invoice> {
    const url = `${ApiEndpoints.Invoices}/${id}/complete`;
    return this.http.put<Invoice>(url, {});
  }

  CancelInvoice(id: number, cancellationReason: string): Observable<Invoice> {
    const url = `${ApiEndpoints.Invoices}/${id}/cancel?cancellationReason=${encodeURI(cancellationReason)}`;
    return this.http.put<Invoice>(url, {});
  }
}
