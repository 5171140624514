import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { ErrorProvider } from 'src/app/core/providers/error.provider';
import { HttpClient } from '@angular/common/http';
import { NotifyService } from 'src/app/core/services/notify.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
  emailSubscribe: string = '';

  constructor(private _err: ErrorProvider, private http: HttpClient, private _notify: NotifyService) {}

  ngOnInit() {}

  public normalError() {}

  subscribe(event: Event) {
    event.preventDefault();

    const formData = new FormData();
    formData.append('email', this.emailSubscribe);
    formData.append('origin', 'newsletter sign up(homepage)');
    this.http.post('https://www.winerist.com/?ACT=55', formData).subscribe(
      (r) => {
        this.emailSubscribe = '';
        this._notify.Success('You were subscribed to our newsletter!');
      },
      (e) => this._notify.Error('Something went wrong, contact please Administrator')
    );
  }
}
