import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient } from '@angular/common/http';
import { mapTo } from 'rxjs/operators';

import { ApiEndpoints } from 'src/app/core/infrastructure/application.config';
import { SimpleItem } from 'src/app/core/models/account/simple-item.model';
import { User } from 'src/app/core/models/partner/user.model';

@Injectable()
export class UserProvider {
  private statuses: SimpleItem[] = [
    {
      id: 0,
      name: 'Disabled'
    },
    {
      id: 1,
      name: 'Enabled'
    },
    {
      id: 2,
      name: 'Pending'
    }
  ];

  constructor(private http: HttpClient) {}

  Add(user: User): Observable<User> {
    const url = ApiEndpoints.User;

    return this.http.post<User>(url, user);
  }

  Edit(user: User): Observable<User> {
    const url = ApiEndpoints.User;

    return this.http.put<User>(url, user);
  }

  Delete(id: string): Observable<boolean> {
    const url = `${ApiEndpoints.User}/${id}`;

    return this.http.delete(url).pipe(mapTo(true));
  }

  GetList(): Observable<User[]> {
    const url = ApiEndpoints.User;

    return this.http.get<User[]>(url);
  }

  GetUser(id: string): Observable<User> {
    const url = `${ApiEndpoints.User}/${id}`;

    return this.http.get<User>(url);
  }

  GetStatuses() {
    return this.statuses;
  }
}
