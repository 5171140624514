import { Injectable } from '@angular/core';

import { LocalStorageAdapter } from './local-storage.adapter';
import { JwtHelperService } from '@auth0/angular-jwt';
import { RoleCode } from 'src/app/pages/users/add-user';
import { ITokens } from '../interfaces/itokens.interface';

@Injectable()
export class AuthenticationContext {
  private readonly _auth = 'Authorization';
  private readonly _partner = 'Partner';
  private readonly _userId = 'UserId';
  private readonly _userEmail = 'UserEmail';
  private readonly _role = 'Role';
  private readonly _refreshToken = 'RefreshToken';
  private _jwtHelper = new JwtHelperService();

  constructor(private readonly _storage: LocalStorageAdapter) {}

  public get Auth(): string {
    return this._storage.GetItem(this._auth);
  }

  public set Auth(value: string) {
    this._storage.SetItem(this._auth, value);
  }

  public get Partner(): string {
    return this._storage.GetItem(this._partner);
  }

  public set Partner(value: string) {
    this._storage.SetItem(this._partner, value);
  }

  public get UserId(): string {
    return this._storage.GetItem(this._userId);
  }

  public set UserId(value: string) {
    this._storage.SetItem(this._userId, value);
  }

  public get UserEmail(): string {
    return this._storage.GetItem(this._userEmail);
  }

  public set UserEmail(value: string) {
    this._storage.SetItem(this._userEmail, value);
  }

  public get Role(): RoleCode {
    return this._storage.GetItem(this._role) as RoleCode;
  }

  public set Role(value: RoleCode) {
    this._storage.SetItem(this._role, value);
  }

  public get RefreshToken(): string {
    return this._storage.GetItem(this._refreshToken);
  }

  public set RefreshToken(value: string) {
    this._storage.SetItem(this._refreshToken, value);
  }

  public ClearContext(): void {
    this._storage.RemoveItem(this._auth);
    this._storage.RemoveItem(this._partner);
    this._storage.RemoveItem(this._userId);
    this._storage.RemoveItem(this._role);
  }

  public SetContext(identityResponse: Partial<ITokens>): void {
    this.Auth = `Bearer ${identityResponse.access_token}`;

    const accessToken = this._jwtHelper.decodeToken(identityResponse.access_token);

    this.Partner = accessToken.partnerId;
    this.UserId = accessToken.id;
    this.UserEmail = accessToken.email;
    this.Role = accessToken.role;
    this.RefreshToken = identityResponse.refresh_token;
  }
}
