import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { ApplicationError } from './application-error';
import { HttpApplicationError } from './http-application-error';
import { NotifyService } from './services/notify.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: any): void {
    const _notify = this.injector.get(NotifyService);
    if (error instanceof ApplicationError) {
    }

    if (error instanceof HttpApplicationError) {
      // TODO refactoring for error handler
      if (error.error.error) {
        for (const key in error.error.error) {
          if (error.error.error.hasOwnProperty(key)) {
            for (let i = 0; i < error.error.error[key].length; i++) {
              _notify.Error(error.error.error[key][i]);
            }
          }
        }
      } else {
        _notify.Error('Something went wrong, contact please Administrator');
      }
    }
  }
}
