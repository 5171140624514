import { BankRequisites } from './../models/partner/bank-requisites.model';
import { BillingDetails } from './../models/partner/billing-details.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

import { ApiEndpoints } from 'src/app/core/infrastructure/application.config';
import { Partner, PartnerDetails } from 'src/app/core/models/partner/partner.model';
import { AuthenticationContext } from 'src/app/core/infrastructure/authentication.context';
import { mapTo, concatMap, map } from 'rxjs/operators';
import { PartnerLogo } from '../models/partner/partner-logo.model';
import { of } from 'rxjs/internal/observable/of';
import { PaynetAccount } from '../models/partner/paynet-account.model';
import { FilterItem } from '../models/filter-item.model';

@Injectable()
export class PartnerProvider {
  private _partner: Partner;

  constructor(private readonly _ctx: AuthenticationContext, private http: HttpClient) {}

  async updateCustomerSubscriptionPaymentMethod(paymentMethodId: string): Promise<string> {
    const url = `${ApiEndpoints.PartnerAdmin}/update-subscription-payment-method`;
    return await this.http.post<string>(url, { paymentMethodId: paymentMethodId }).toPromise();
  }

  async GetPartner(): Promise<Partner> {
    if (!this._partner && this._ctx.Partner) {
      const url = `${ApiEndpoints.PartnerAdmin}/${this._ctx.Partner}`;
      this._partner = await this.http.get<Partner>(url).toPromise();
    }

    return this._partner;
  }

  async createStripeSubscription(billingDetails: BillingDetails): Promise<{ clientSecret; subscriptionId }> {
    const url = `${ApiEndpoints.PartnerAdmin}/create-subscription`;

    return await this.http.post<{ clientSecret; subscriptionId }>(url, billingDetails).toPromise();
  }

  async updateBillingDetails(billingDetails: BillingDetails): Promise<{ clientSecret; subscriptionId }> {
    const url = `${ApiEndpoints.PartnerAdmin}/update-subscription-billing-details`;

    return await this.http.post<{ clientSecret; subscriptionId }>(url, billingDetails).toPromise();
  }

  async cancelSubscription(): Promise<{}> {
    const url = `${ApiEndpoints.PartnerAdmin}/cancel-subscription`;
    return await this.http.post<{}>(url, { partnerId: +this._ctx.Partner }).toPromise();
  }

  async getSubscription(): Promise<Subscription> {
    const url = `${ApiEndpoints.PartnerAdmin}/get-subscription`;
    return await this.http.get<Subscription>(url).toPromise();
  }

  async deletePartner(partnerId: number) {
    const url = `${ApiEndpoints.PartnerAdmin}/delete-partner?partnerId=${partnerId}`;
    return await this.http.delete(url).toPromise();
  }

  ResetPartner(): void {
    this._partner = undefined;
  }

  Edit(partner: Partner): Observable<Partner> {
    const url = `${ApiEndpoints.PartnerAdmin}`;

    return this.http.put<Partner>(url, partner);
  }

  SavePaynetData(partnerId: number, paynetAccount: PaynetAccount): Observable<void> {
    const url = `${ApiEndpoints.PartnerAdmin}/${partnerId}/paynet-data`;

    return this.http.put<any>(url, paynetAccount);
  }

  saveBankData(bankAccount: BankRequisites): Observable<void> {
    const url = `${ApiEndpoints.PartnerAdmin}/bank-data`;

    return this.http.put<any>(url, bankAccount);
  }

  private _GetAllPartnersCache: Record<string, GetAllPartnersResponse> = {};
  private _GetAllPartnerDetailsCache: Record<string, PartnerDetails[]> = {};

  GetAllPartnersClearCache(): void {
    this._GetAllPartnersCache = {};
  }

  GetAllPartners(params?: any): Observable<GetAllPartnersResponse> {
    if (!params) params = { page: 0, pageSize: 10 };

    const url = `${ApiEndpoints.PartnerAdmin}`;

    const cacheKey = url + JSON.stringify(params);
    const cachedResponse = this._GetAllPartnersCache[cacheKey];

    if (cachedResponse) {
      return of(cachedResponse);
    }

    return this.http.get<any>(url, { params: params, observe: 'response' }).pipe(
      map((res: any) => {
        const data = { partners: res.body, totalCount: res.headers.get('total-rows-count') };

        this._GetAllPartnersCache[cacheKey] = data;

        return data;
      })
    );
  }

  GetPartnersFilterItems(filter: PartnersFilterItemsCriteria): Promise<FilterItem<number>[]> {
    const url = `${ApiEndpoints.PartnerAdmin}/filter-items`;
    let params = {};
    params = { ...params, ...filter };

    return this.http.get<FilterItem<number>[]>(url, { params }).toPromise();
  }

  GetAllPartnersLight(): Observable<PartnerDetails[]> {
    const url = `${ApiEndpoints.PartnerAdmin}/minimalDetails`;
    const cachedResponse = this._GetAllPartnerDetailsCache[url];

    if (cachedResponse) {
      return of(cachedResponse);
    }

    return this.http.get<PartnerDetails[]>(url).pipe(
      map((res: PartnerDetails[]) => {
        this._GetAllPartnerDetailsCache[url] = res;
        return res;
      })
    );
  }

  ToggleCustomization(status: boolean, partnerId: number): Observable<boolean> {
    const url = `${ApiEndpoints.PartnerAdmin}/${partnerId}/customisation/${status ? 'enable' : 'disable'}`;

    const $customization = this.http.put<boolean>(url, {});
    return $customization.pipe(
      concatMap((r) => this.GetPartner()),
      mapTo(true)
    );
  }

  TogglePartnersAllProductsStatus(status: boolean, partnerId: number): Observable<boolean> {
    const url = `${ApiEndpoints.PartnerAdmin}/${partnerId}/${status ? 'enable-products' : 'disable-products'}`;

    return this.http.put<boolean>(url, {});
  }

  UploadPartnerLogo(parnterId: number, file: any): Observable<PartnerLogo> {
    const formData = new FormData();
    formData.append('file', file);
    const url = `${ApiEndpoints.PartnerAdmin}/${parnterId}/logo`;

    return this.http.post<PartnerLogo>(url, formData);
  }
}

interface GetAllPartnersResponse {
  partners: Partner[];
  totalCount: number;
}

export interface PartnersFilterItemsCriteria {
  countryId?: number;
  regionId?: number;
}

export interface Subscription {
  cancelAtPeriodEnd: boolean;
  endDate: string;
  paymentMethod: PaymentMethod;
  id: string;
  billingDetails?: BillingDetails;
}

export interface PaymentMethod {
  expMonth: number;
  expYear: number;
  last4: string;
  brand: CardBrand;
}

export enum CardBrand {
  mastercard = 'mastercard',
  visa = 'visa',
  discover = 'discover',
  amex = 'amex'
}
