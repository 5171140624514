import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient } from '@angular/common/http';
import { ApiEndpoints } from 'src/app/core/infrastructure/application.config';

@Injectable()
export class FareharborProvider {
  _fareharborUrl = `${ApiEndpoints.Admin}/fareharbor`;
  _fareharborCompaniesUrl: string;
  constructor(private http: HttpClient) {}

  getSchemasList(companyName: string, productId: string, currency: string): Observable<FareharborCompany> {
    const url = `${this._fareharborUrl}/company/${companyName}?currency=${currency}&productId=${productId}`;

    return this.http.get<FareharborCompany>(url);
  }

  getCompanies(): Observable<FareharborCompany[]> {
    const url = `${this._fareharborUrl}/companies`;

    return this.http.get<FareharborCompany[]>(url);
  }

  getProducts(companyName: string, currency: string): Observable<FareharborProduct[]> {
    const url = `${this._fareharborUrl}/company/${companyName}?currency=${currency}`;

    return this.http.get<FareharborProduct[]>(url);
  }
}

export interface FareharborSchema {
  id: number;
  name: string;
  price: number;
}

export interface FareharborCompany {
  name: string;
  shortName: string;
  currency: string;
}

export interface FareharborProduct {
  id: string;
  name: string;
  fareharborSchemas: FareharborSchema[];
}
