import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

import { LoginModel } from 'src/app/core/models/account/login.model';
import { SignUpModel } from 'src/app/core/models/account/signup.model';
import { AuthenticationContext } from 'src/app/core/infrastructure/authentication.context';
import { ApiEndpoints } from 'src/app/core/infrastructure/application.config';
import { tap } from 'rxjs/operators';
import { ITokens } from '../interfaces/itokens.interface';
import { User } from 'src/app/core/models/partner/user.model';

@Injectable()
export class AccountProvider {
  constructor(private http: HttpClient, private readonly _authCtx: AuthenticationContext) {}

  get bookingsUrl(): string {
    return '/main/bookings';
  }

  SignIn(loginModel: LoginModel): Observable<any> {
    const url = `${ApiEndpoints.Connect}/token`;
    const formData = new FormData();
    formData.append('username', loginModel.login);
    formData.append('password', loginModel.password);
    formData.append('client_id', 'platform.management');
    // It is necessairy to add offline_access  to list of requested scopes in order to receive refresh token data
    // http://docs.identityserver.io/en/latest/topics/refresh_tokens.html#requesting-a-refresh-token
    formData.append('scope[]', '["partner.profile", "offline_access"]');
    formData.append('grant_type', 'password');

    return this.http.post(url, formData).pipe(
      tap((response: ITokens) => {
        this._authCtx.SetContext(response);
      })
    );
  }

  updateAccessToken() {
    const url = `${ApiEndpoints.Connect}/token`;
    const formData = new FormData();
    formData.append('grant_type', 'refresh_token');
    formData.append('client_id', 'platform.management');
    formData.append('refresh_token', this._authCtx.RefreshToken);
    return this.http.post(url, formData).pipe(
      tap((response: ITokens) => {
        this._authCtx.SetContext(response);
      })
    );
  }

  SignUp(signUpModel: SignUpModel): Observable<any> {
    const url = `${ApiEndpoints.Accounts}/signup`;
    return this.http.post(url, signUpModel);
  }

  ActivatePartner(token: string): Observable<any> {
    const _token = { token: token };
    const url = `${ApiEndpoints.Accounts}/activate`;
    return this.http.post(url, _token);
  }

  AddAccount(user: User, type: string): Observable<User> {
    const url = `${ApiEndpoints.AccountsAdmin}/${type.replace('.', '-')}`;

    return this.http.post<User>(url, user);
  }

  EditAccount(user: User, type: string): Observable<User> {
    const url = `${ApiEndpoints.AccountsAdmin}/${type.replace('.', '-')}`;

    return this.http.put<User>(url, user);
  }

  ActivateAccount(password: string, token: string): Observable<{ access_token: string }> {
    const url = `${ApiEndpoints.Accounts}/activate/user`;

    return this.http.post<{ access_token: string }>(url, { password, token });
  }

  ForgotPassword(email: string): Observable<any> {
    const url = `${ApiEndpoints.Accounts}/forgot-password?email=${email}`;

    return this.http.post<any>(url, {});
  }

  ResetPassword(token: string, password: string): Observable<any> {
    const url = `${ApiEndpoints.Accounts}/reset-password`;

    return this.http.post<any>(url, { token: token, password: password });
  }

  Impersonate(userId: number): Observable<unknown> {
    const url = `${ApiEndpoints.AccountsAdmin}/impersonate`;

    return this.http.post<string>(url, { userId }).pipe(
      tap((access_token: string) => {
        this._authCtx.SetContext({ access_token });
      })
    );
  }

  get IsAuthenticated(): boolean {
    return !!this._authCtx.Auth;
  }

  HasRole(...roles: string[]): boolean {
    return roles.indexOf(this._authCtx.Role) !== -1;
  }
}
