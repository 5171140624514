import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AdditionalFilters, GridFiltersType } from 'src/app/controls/grid/helpers/grid-filter.utils';
import { GridLoadOptions, GridPageData } from 'src/app/controls/grid/helpers/grid.model';
import { GiftCardOrderItemStatus, GiftCardStatus } from 'src/app/core/enums/giftcard.enum';
import { ApiEndpoints } from 'src/app/core/infrastructure/application.config';

export interface GiftCardFilters extends GridFiltersType {
  giftCardOrderItemStatus: GiftCardOrderItemStatus[];
}

export interface AdditionalGiftCardCodeFilter extends AdditionalFilters {
  giftCardCodes: string[];
}

export interface GiftCardGridItemModel {
  code: string;
  bookingReferences: number[];
  customerFullName: string;
  recipientFullName: string;
  type: GiftCardStatus;
  giftCardOrderItemStatus: GiftCardOrderItemStatus;
  currency: string;
  amount: number;
  availableAmount: number;
  purchaseDate: string;
}

@Injectable()
export class GiftCardProvider {
  constructor(private http: HttpClient) {
  }

  // TODO: Ion check for unsubscribe
  async getGiftCardDashboard(filters: GridLoadOptions<GiftCardFilters>): Promise<GridPageData<GiftCardGridItemModel>> {
    const url = `${ApiEndpoints.Admin}/giftcardorderitems`;

    return await this.http.post<GridPageData<GiftCardGridItemModel>>(url, filters).toPromise();
  }
}
