import DevExpress from 'devextreme/bundles/dx.all';

export const ApiEndpoints = {
  Accounts: '/api/v1/account',
  AccountsAdmin: '/api/admin/v1/account',
  AuditBookingLog: '/api/admin/v1/audit/booking',
  AuditGiftCardLog: '/api/admin/v1/audit/giftcard',
  Admin: '/api/admin/v1',
  User: '/api/v1/users',
  Ticket: '/api/admin/v1/tickets',
  Product: '/api/v1/products',
  ProductAdmin: '/api/admin/v1/products',
  ProductOptionAdmin: '/api/admin/v1/productoptions',
  Bookings: '/api/v1/bookings',
  BookingsAdmin: '/api/admin/v1/bookings',
  Location: '/api/v1/locations',
  LocationAdmin: '/api/admin/v1/locations',
  Connect: '/api/connect',
  Locale: '/api/v1/locale',
  Payment: '/api/v1/payments',
  Partner: '/api/v1/partners',
  PartnerAdmin: '/api/admin/v1/partners',
  Invoices: '/api/v1/invoices',
  Reporting: '/api/v1/reporting',
  Enquiries: '/api/enquiries'
};

export const dxCalendarOptions: DevExpress.ui.dxCalendarOptions = {
  firstDayOfWeek: 1 // Monday
};
