import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient } from '@angular/common/http';

import { ApiEndpoints } from 'src/app/core/infrastructure/application.config';
import { PerformanceReport } from 'src/app/core/models/reports/sales-performance.model';

@Injectable()
export class ReportingProvider {
  constructor(private http: HttpClient) {}

  CustomersListing(filter?: { from?: string; to?: string }): Observable<PerformanceReport[]> {
    const params = {};
    if (filter)
      Object.keys(filter).forEach((key) => {
        if (filter[key]) params[key] = filter[key];
      });

    const url = `${ApiEndpoints.Reporting}/customers-listing`;
    return this.http.get<PerformanceReport[]>(url, { params });
  }
}
