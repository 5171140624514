<header>
  <div class="container">
    <div class="row">
      <div class="col-6 col-sm-3">
        <div class="logo">
          <a routerLink="/">
            <img src="assets/images/logo.png" />
          </a>
        </div>
      </div>
      <div class="col-6 col-sm-9">
        <ul class="menu">
          <li><a href="http://winerist.com" target="_blank">Winerist</a></li>
          <li>
            <a routerLink="/account/login"><img src="assets/images/login.svg" class="user" />Login</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</header>
<main>
  <article class="winerist__partner">
    <div class="wrap container">
      <div class="row">
        <div class="col-12 col-sm-6 winerist__partner-padding">
          <h1 class="winerist__partner--title">Start using WineristGO today!</h1>
          <p class="winerist__partner--text">
            Welcome to WineristGO, the first booking tool for Wine and Food Hospitality Service Providers
          </p>
          <button routerLink="/account/signup" class="button__default">Get Started</button>
        </div>
        <div class="col-12 col-sm-6">
          <div class="winerist__partner--image">
            <div class="partner__top">
              <img class="first-image" src="assets/images/ready-to-rock.jpeg" alt="image" />
              <img src="assets/images/Calzone, Italy.jpg" alt="image" />
            </div>

            <div class="partner__bottom">
              <img class="first-image" src="assets/images/AdobeStock_118525732.jpeg" alt="image" />
              <img src="assets/images/AdobeStock_111800235.jpeg" alt="image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
  <article class="you__can">
    <div class="container">
      <h4 class="you__can--title">With WineristGO, You Can:</h4>
      <div class="row">
        <div class="col-md-4">
          <div class="you__can--block">
            <img src="assets/images/sales.svg" alt="sales" />
            <p>Grow Sales</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="you__can--block">
            <img src="assets/images/operation.svg" alt="operations" />
            <p>Automate<br />Operations</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="you__can--block">
            <img src="assets/images/client.svg" alt="client" />
            <p>Improve Client<br />Communication</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="you__can--block">
            <img src="assets/images/partner.svg" alt="partners" />
            <p>Work with Third<br />Party Partners</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="you__can--block">
            <img src="assets/images/time.svg" alt="time" />
            <p>Spend More Time<br />Making Wine!</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="you__can--block">
            <img src="assets/images/payment.svg" alt="payments" />
            <p>
              Guarantee Payment<br />
              upon Booking
            </p>
          </div>
        </div>
      </div>
    </div>
  </article>

  <article class="booking">
    <div class="container">
      <div class="row">
        <div class="col-md-4 booking__perfect--for">
          <h4 class="booking--title">WineristGO is perfect for:</h4>
        </div>

        <div class="col-md-4">
          <p class="booking--info"><img src="assets/images/check.svg" alt="checkout" />Wineries and Distilleries</p>
          <p class="booking--info"><img src="assets/images/check.svg" alt="checkout" />Wine Hotels</p>
        </div>

        <div class="col-md-4">
          <p class="booking--info"><img src="assets/images/check.svg" alt="checkout" />Wine Tour Operators</p>
          <p class="booking--info"><img src="assets/images/check.svg" alt="checkout" />Food Tour Operators</p>
        </div>
        <div class="col-12">
          <button routerLink="/account/signup" class="button__default">Get Started</button>
        </div>
      </div>
    </div>
  </article>

  <article class="works">
    <div class="container">
      <h3 class="works--title">How it Works</h3>
      <div class="row works_wrap">
        <div class="col work__block">
          <div class="work__block--content">
            <img src="assets/images/user_plus.svg" alt="image" />
            <h5 class="work__block--title">Create a WineristGO account</h5>
            <p class="work__block--text">
              Creating a WineristGO account is fast and simple. No hidden costs or extra fees, create an account in 10
              minutes and you are ready to get started!
            </p>
          </div>
        </div>
        <div class="col work__block">
          <div class="work__block--content">
            <img src="assets/images/connect.svg" alt="image" />
            <h5 class="work__block--title">Connect WineristGO to your website</h5>
            <p class="work__block--text">
              Connecting the WineristGO booking tool to your website is extremely simple. Our system was smartly
              designed so all you need to do is copy and paste a link. It won't take you more than 5 minutes!
            </p>
          </div>
        </div>
        <div class="col work__block">
          <div class="work__block--content">
            <img src="assets/images/payment_solution.svg" alt="image" />
            <h5 class="work__block--title">Smart payment system</h5>
            <p class="work__block--text">
              WineristGO works with Stripe to provide you with a hassle free payment experience. No manual invoicing, no
              hassle, no hidden fees. Once you create a Stripe account, you will receive your payments directly to your
              Bank account.
            </p>
          </div>
        </div>
        <div class="col work__block">
          <div class="work__block--content">
            <img src="assets/images/credit-card.svg" alt="image" />
            <h5 class="work__block--title">Pricing</h5>
            <p class="work__block--text">
              WineristGO simply charges 3% on the booking price! No monthly or annual subscription fees
            </p>
          </div>
        </div>
        <div class="col work__block">
          <div class="work__block--content">
            <img src="assets/images/book.svg" alt="image" />
            <h5 class="work__block--title">Start receiving bookings online</h5>
            <p class="work__block--text">Start receiving bookings online 24/7</p>
          </div>
        </div>
      </div>
    </div>
  </article>

  <article class="about">
    <div class="container">
      <div class="row">
        <div class="col-md-3 about__winersit">
          <h4 class="about--title">Winerist Integration</h4>
          <p class="about--text">Get listed on Winerist with the click of a button</p>
          <a class="about--link" target="_blank" href="https://www.winerist.com">www.winerist.com</a>
        </div>

        <div class="col-md-9 about__flow">
          <img class="arrow-right" src="assets/images/blue arrow.png" alt="" />
          <div class="row">
            <div class="col-md-6">
              <div class="about__logo">
                <img src="assets/images/logo.png" alt="WINERISTGO" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="about__logo">
                <img src="assets/images/winerist_logo.png" alt="winerist" />
              </div>
            </div>
          </div>
          <img class="arrow-left" src="assets/images/red arrow.png" alt="" />
        </div>
      </div>
      <div class="row about__wrap">
        <div class="col">
          <div class="about__info">
            <img src="assets/images/barrel.png" alt="icon" />
            <div class="about__info-block">
              <p class="about__info--text">350</p>
              <strong class="about__info--title">Wineries</strong>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="about__info">
            <img src="assets/images/bag.svg" alt="icon" />
            <div class="about__info-block">
              <p class="about__info--text">1000</p>
              <strong class="about__info--title">Tours</strong>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="about__info">
            <img src="assets/images/pin.svg" alt="icon" />
            <div class="about__info-block">
              <p class="about__info--text">160</p>
              <strong class="about__info--title">Locations</strong>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="about__info">
            <img src="assets/images/users.svg" alt="icon" />
            <div class="about__info-block">
              <p class="about__info--text">600,000</p>
              <strong class="about__info--title">Users</strong>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="about__info">
            <img src="assets/images/like.svg" alt="icon" />
            <div class="about__info-block">
              <p class="about__info--text">100,000</p>
              <strong class="about__info--title">Followers</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
  <article class="our__partners">
    <div class="our__partners__top">
      <div class="container">
        <h4 class="our__partners--title">What Our Partners Say About Us</h4>
      </div>
    </div>
    <div class="our__partners__bottom">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <div class="our__partners__testimonials">
              <div class="testimonials__image">
                <img src="assets/images/miguel.png" alt="" />
              </div>
              <strong class="tstimonials--title">Miguel, Clos Figueras</strong>
              <img class="quotes" src="assets/images/quotes.svg" alt="iamege" />
              <p class="testimonials--text">
                Winerist is the best formula to provide the information of most interesting places. Clos Figueras is
                privileged to be chosen by Winerist as one of the places of interest and quality to the wide world.
              </p>
            </div>
          </div>

          <div class="col-md-4">
            <div class="our__partners__testimonials">
              <div class="testimonials__image">
                <img src="assets/images/peirre.png" alt="" />
              </div>
              <strong class="tstimonials--title">Peirre - Jean, Bordovino</strong>
              <img class="quotes" src="assets/images/quotes.svg" alt="iamege" />
              <p class="testimonials--text">
                After only 2 years Winerist accounts for one of our key partners. We work on a daily basis together.
                Working with many tour operators worldwide. I can tell that there is no other examples of such
                tremendous development.
              </p>
            </div>
          </div>

          <div class="col-md-4">
            <div class="our__partners__testimonials">
              <div class="testimonials__image">
                <img src="assets/images/rosa_silva.png" alt="" />
                <img src="assets/images/rosa_silva_2.png" alt="" />
              </div>
              <strong class="tstimonials--title">Rosa Silva, Living Tours Portugal</strong>
              <img class="quotes" src="assets/images/quotes.svg" alt="iamege" />
              <p class="testimonials--text">
                Winerist is the best formula to provide the information of most interesting places. Clos Figueras is
                privileged to be chosen by Winerist as one of the places of interest and quality to the wide world.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
  <article class="ready">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 d-block d-sm-none">
          <h4 class="ready--title">Why not get started today?</h4>
          <p class="ready--text">
            Reach out to us if you have any questions. <a href="mailto:partners@winerist.com">partners@winerist.com</a>
          </p>
          <button routerLink="/account/signup" class="button__default">Get Started</button>
        </div>
        <div class="col-12 ready__background">
          <div class="gradient d-none d-sm-block"></div>
          <div class="ready__background_info d-none d-sm-block">
            <h4 class="ready--title">Why not get started today?</h4>
            <p class="ready--text">
              Reach out to us if you have any questions.
              <a href="mailto:partners@winerist.com">partners@winerist.com</a>
            </p>
            <button routerLink="/account/signup" class="button__default">Get Started</button>
          </div>
        </div>
      </div>
    </div>
  </article>
</main>
<footer>
  <section class="newsletter">
    <div class="container">
      <div class="row">
        <div class="col-sm-6 newsletter__form">
          <div class="container">
            <div class="row">
              <div class="col-sm-4 newsletter__form-title"><span>Join our newsletter</span></div>
              <div class="col-sm-8">
                <form (submit)="subscribe($event)">
                  <input type="text" [(ngModel)]="emailSubscribe" placeholder="Enter your email" />
                  <input type="submit" value="Subscribe" class="submit" />
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 newsletter__social">
          <div class="container">
            <div class="row">
              <div class="col-sm-4 newsletter__social-title"><span>Social Media</span></div>
              <div class="col-sm-8">
                <ul class="social-menu">
                  <li>
                    <a class="facebook" href="https://www.facebook.com/winerist/"
                      ><i class="fab fa-facebook-square"></i
                    ></a>
                  </li>
                  <li>
                    <a class="twitter" href="https://twitter.com/TheWinerist"><i class="fab fa-twitter"></i></a>
                  </li>
                  <li>
                    <a class="youtube" href="https://www.youtube.com/channel/UCxFIqkY9PFRRRre7coHUk_g"
                      ><i class="fab fa-youtube"></i
                    ></a>
                  </li>
                  <li>
                    <a class="instagram" href="https://www.instagram.com/winerist/"><i class="fab fa-instagram"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="footer-bottom">
    <div class="container">
      <div class="row">
        <div class="col-sm-3 col-4">
          <ul class="menu-footer">
            <li><a target="_blank" href="https://www.winerist.com/about-us">About Us </a></li>
            <li><a target="_blank" href="https://www.winerist.com/contact-us">Contact Us</a></li>
            <li><a target="_blank" href="https://www.winerist.com/about-us/press">Press</a></li>
          </ul>
        </div>
        <div class="col-sm-3 col-4">
          <ul class="menu-footer">
            <li><a target="_blank" href="https://www.winerist.com/jobs">Jobs</a></li>
            <li><a target="_blank" href="https://www.winerist.com/faq">FAQ</a></li>
            <!-- <li><a target="_blank" href="https://www.winerist.com/general/sitemap">Sitemap</a></li> -->
          </ul>
        </div>
        <div class="col-sm-3 col-4">
          <ul class="menu-footer">
            <li><a target="_blank" href="https://www.winerist.com/terms-conditions">Terms & Conditions</a></li>
            <li><a target="_blank" href="https://www.winerist.com/privacy-and-security">Privacy & Security</a></li>
            <!-- <li><a target="_blank" href="https://www.winerist.com/general/static_page/general-information">General
                                information</a></li> -->
          </ul>
        </div>
        <div class="col-sm-3 col-12">
          <p class="text-payment">Secure online payments</p>
          <ul class="menu-payment">
            <li>
              <a href="#"><img src="assets/images/master.png" alt="" /></a>
            </li>
            <li>
              <a href="#"><img src="assets/images/american.png" alt="" /></a>
            </li>
            <li>
              <a href="#"><img src="assets/images/visa.png" alt="" /></a>
            </li>
          </ul>
        </div>
        <div class="col-12">
          <p class="copyright">© Copyright 2019 Winerist.com</p>
        </div>
      </div>
    </div>
  </section>
</footer>
