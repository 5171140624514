import { Injectable } from '@angular/core';
import { ApiEndpoints } from '../infrastructure/application.config';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { BookingStatus } from '../enums/bookig-status.enum';
import { GridLoadOptions } from 'src/app/controls/grid/helpers/grid.model';
import { GridFiltersType } from 'src/app/controls/grid/helpers/grid-filter.utils';
import { PricingSchemeTypeId } from '../enums/pricing-scheme-type-id.enum';

@Injectable()
export class TicketProvider {
  constructor(private http: HttpClient) {}

  getBookingsForEvents(filters: GridLoadOptions<TicketFilters>): Observable<EventBookingGridItemModel[]> {
    const url = ApiEndpoints.Ticket;

    return this.http.post<EventBookingGridItemModel[]>(url, filters);
  }

  getTicketByIdentifier(ticketIdentifier: string): Observable<TicketModel | undefined> {
    const url = `${ApiEndpoints.Ticket}/${ticketIdentifier}`;

    return this.http.get<TicketModel | undefined>(url);
  }

  getEventProgress(): Observable<EventProgress> {
    const url = `${ApiEndpoints.Ticket}/event/progress`;

    return this.http.get<EventProgress>(url);
  }

  approveTicket(ticketIdentifier: string): Observable<ApproveTicketResponse> {
    const url = `${ApiEndpoints.Ticket}/approve`;

    return this.http.post<ApproveTicketResponse>(url, { ticketIdentifier: ticketIdentifier });
  }
}

export interface TicketFilters extends GridFiltersType {
  partnerId: number[];
  productId: number[];
  status: BookingStatus[];
}

export interface EventProgress {
  total: number;
  value: number;
  productName: string;
}

export interface TicketModel {
  isUsed: boolean;
  bookingStatus: BookingStatus;
  bookingStatusDate: Date;
  usedAt: Date;
  eventName: string;
  ticketName: string;
  bookingReference: string;
}

export interface ApproveTicketResponse {
  isSuccess: boolean;
  message: string;
  usedAt: Date;
}

export interface EventBookingGridItemModel {
  id: number;
  bookingStatus: BookingStatus;
  currency: string;
  productName: string;
  productOptionName: string;
  customerName: string;
  phone: string;
  email: string;
  purchaseDate: Date;
  bookingReference: string;
  commissionAmount: number;
  totalAmount: number;
  partnerName: string;
  tickets: { isUsed: boolean; pricingSchemaId: PricingSchemeTypeId }[];
}
