import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

import { ApiEndpoints } from 'src/app/core/infrastructure/application.config';
import { ProductType } from 'src/app/core/models/productType/product-type.model';
import { LightProduct, Product, ProductListItem } from 'src/app/core/models/product/product.model';
import { ProductFile } from 'src/app/core/models/product/product-file.model';
import { ProductAggregatedAvailability } from 'src/app/core/models/product/product-aggregated-availability.model';
import { tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ProductTypeId } from '../enums/product-type.enum';
import { ProductStatusInt } from '../enums/product-status.enum';
import { GridPageInfo, GridPageData } from 'src/app/controls/grid/helpers/grid.model';
import { SimpleItem } from '../models/account/simple-item.model';
import { FilterItem } from '../models/filter-item.model';

@Injectable()
export class ProductProvider {
  constructor(private http: HttpClient) {}

  getProduct(id: number): Observable<Product> {
    const url = `${ApiEndpoints.ProductAdmin}/${id}`;

    return this.http.get<Product>(url);
  }

  private GetProductTypeCache: Record<number, ProductType> = {};

  getProductType(id: number): Observable<ProductType> {
    if (this.GetProductTypeCache[id]) {
      return of(this.GetProductTypeCache[id]);
    }

    const url = `${ApiEndpoints.ProductAdmin}/types/${id}`;

    return this.http.get<ProductType>(url).pipe(tap((response) => (this.GetProductTypeCache[id] = response)));
  }

  getTourTypes(): Promise<SimpleItem[]> {
    const url = `${ApiEndpoints.ProductAdmin}/tour-types`;
    return this.http.get<SimpleItem[]>(url).toPromise();
  }

  getAllApprovedProducts(productTypeId?: number, countryId?: number): Observable<LightProduct[]> {
    const params = {};

    if (productTypeId) params['productTypeId'] = productTypeId;
    if (countryId) params['countryId'] = countryId;

    const url = `${ApiEndpoints.ProductAdmin}/list`;

    return this.http.get<LightProduct[]>(url, { params });
  }

  getProductsByType(request: GetProductsByTypeRequest): Observable<GetProductsByTypeResponse> {
    const { productTypeId, pageInfo, name, partnerIds, statuses } = request;

    let params = {
      type: productTypeId.toString()
    };

    params = { ...params, ...pageInfo };
    name && (params = { ...params, ...{ name } });
    partnerIds && (params = { ...params, ...{ partnerId: partnerIds } });
    statuses && (params = { ...params, ...{ status: statuses } });

    const url = ApiEndpoints.ProductAdmin;

    return this.http.get<GetProductsByTypeResponse>(url, { params });
  }

  getProductFilterItems(filter: ProductFilterItemsCriteria): Promise<FilterItem<number>[]> {
    const url = `${ApiEndpoints.ProductAdmin}/filter-items`;
    let params = {};
    params = { ...params, ...filter };

    return this.http.get<FilterItem<number>[]>(url, { params }).toPromise();
  }

  getProductAggregatedAvalability(
    from: string,
    to: string,
    optionId: number
  ): Observable<ProductAggregatedAvailability[]> {
    const params = {};
    if (from) params['from'] = from;
    if (to) params['to'] = to;
    params['optionId'] = optionId;
    const url = `${ApiEndpoints.Product}/availability/aggregated`;
    return this.http.get<ProductAggregatedAvailability[]>(url, { params });
  }

  getProductByPartner(partnerId: number): Observable<GetPartnerProductsResponse> {
    let params = {};
    params['partnerId'] = partnerId;
    const url = `${ApiEndpoints.ProductAdmin}/partner-products`;

    return this.http.get<GetPartnerProductsResponse>(url, { params });
  }

  manage(product: Product): Observable<Product> {
    const url = `${ApiEndpoints.ProductAdmin}`;

    if (product.typeId == ProductTypeId.Event) return this.addEvent(product);

    if (product.id) return this.http.put<Product>(url, product);
    else return this.http.post<Product>(url, product);
  }

  addEvent(product: Product): Observable<Product> {
    if (product.id) return this.http.post<Product>(`${ApiEndpoints.ProductAdmin}/update-event`, product);
    else return this.http.post<Product>(`${ApiEndpoints.ProductAdmin}/create-event`, product);
  }

  addProductFile(productId: number, files: ProductFile[]): Observable<ProductFile>[] {
    const uploads: Observable<any>[] = [];

    files.forEach((file) => {
      const url = `${ApiEndpoints.ProductAdmin}/product/${productId}/upload${file.order ? '?order=' + file.order : ''}`;
      const formData = new FormData();
      formData.append('file', file.file);
      if (file.title) formData.append('title', file.title);
      uploads.push(this.http.post<Product>(url, formData));
    });

    return uploads;
  }

  addOptionFile(files: ProductFile[]): Observable<ProductFile>[] {
    const uploads: Observable<any>[] = [];
    files.forEach((file) => {
      const formData = new FormData();
      const url = `${ApiEndpoints.ProductAdmin}/options/${file.optionId}/upload`;
      formData.append('file', file.file);
      uploads.push(this.http.post<Product>(url, formData));
    });
    return uploads;
  }

  rejectProduct(id: number, reason: string) {
    const url = `${ApiEndpoints.ProductAdmin}/${id}/reject`;

    return this.http.post<Product>(url, { reason: reason });
  }

  approveProduct(id: string) {
    const url = `${ApiEndpoints.ProductAdmin}/${id}/approve`;

    return this.http.post<Product>(url, {});
  }

  closeProduct(id: string) {
    const url = `${ApiEndpoints.ProductAdmin}/${id}/close`;

    return this.http.post<Product>(url, {});
  }

  suspendProduct(id: string) {
    const url = `${ApiEndpoints.ProductAdmin}/${id}/suspend`;

    return this.http.post<Product>(url, {});
  }

  deleteProduct(id: number) {
    return this.http.delete<Product>(`${ApiEndpoints.ProductAdmin}/${id}`);
  }
}

interface GetProductsByTypeRequest {
  productTypeId: ProductTypeId;
  pageInfo?: GridPageInfo;
  name?: string;
  partnerIds?: string[];
  statuses?: string[];
}

interface GetProductsByTypeResponse {
  products: GridPageData<ProductListItem>;
}

export interface PartnerProduct {
  id: number;
  typeId: ProductTypeId;
  name: string;
  status: ProductStatusInt;
  urlTitle: string;
  path: string;
  isPrivate: boolean;
  location: {
    countryName: string;
    regionName: string;
  };
  useRealTimeAvailability: boolean;
  isInstantConfirmation: boolean;
}
export interface GetPartnerProductsResponse {
  products: PartnerProduct[];
}

export interface ProductFilterItemsCriteria {
  countryId?: number;
  regionId?: number;
  includePartnerId?: number[];
  excludePartnerId?: number[];
}
