import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';

import { AuthenticationContext } from 'src/app/core/infrastructure/authentication.context';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/internal/operators/catchError';
import { AccountProvider } from '../providers/account.provider';
import { switchMap } from 'rxjs/operators';
import { UnreportableError } from '../services/rollbar.service';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  constructor(
    private readonly _authCtx: AuthenticationContext,
    private readonly _accountProvider: AccountProvider,
    private router: Router
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (this._authCtx.Auth) {
      request = request.clone({
        headers: request.headers.set('Authorization', `${this._authCtx.Auth}`)
      });
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || error.status === 403) {
          this._authCtx.ClearContext();
          return this.handle401Error(request, next);
        } else {
          return throwError(error);
        }
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    return this._accountProvider.updateAccessToken().pipe(
      switchMap(() => {
        request = request.clone({
          headers: request.headers.set('Authorization', `${this._authCtx.Auth}`)
        });
        return next.handle(request);
      }),
      catchError((_) => {
        this.router.navigateByUrl(`/account/login`);
        return throwError(new UnreportableError('Probably refresh token expired'));
      })
    );
  }
}
