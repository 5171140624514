import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class NotifyService {
  private readonly _time: number = 5000;

  constructor(private toastr: ToastrService) {}

  public Info(message: string, displayTime?: number): void {
    this.toastr.info(message, '', {
      timeOut: displayTime || this._time
    });
  }

  public Warning(message: string, displayTime?: number): void {
    this.toastr.warning(message, '', {
      timeOut: displayTime || this._time
    });
  }

  public Error(message: string, displayTime?: number): void {
    this.toastr.error(message, '', {
      enableHtml: true,
      timeOut: displayTime || this._time
    });
  }

  public Success(message: string, displayTime?: number): void {
    this.toastr.success(message, '', {
      timeOut: displayTime || this._time
    });
  }
}
