import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { AccountProvider } from 'src/app/core/providers/account.provider';

@Injectable()
export class Authorize implements CanActivate {
  constructor(private readonly _router: Router, private readonly _auth: AccountProvider) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this._auth.IsAuthenticated) {
      this._router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }

    return true;
  }
}
