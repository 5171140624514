import { ApplicationError } from './application-error';

export class HttpApplicationError extends ApplicationError {
  error: any;
  status: number;
  constructor(error?: any) {
    super(error);
    this.error = error;
  }
}
