import { Routes } from '@angular/router';
import { Authorize } from './core/guards/authorize.guard';
import { HomeComponent } from './pages/home/home.component';

export const AppRoutes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'ticket-validation/:ticketIdentifier',
    loadChildren: () =>
      import('./pages/ticket-validation/ticket-validation.module').then((m) => m.TicketValidationModule),
    canActivate: [Authorize]
  },
  {
    path: 'action/bookings/:bookingId',
    loadChildren: () => import('./pages/booking-action/booking-action.module').then((m) => m.BookingActionModule),
    canActivate: [Authorize]
  },
  {
    path: 'main',
    loadChildren: () => import('./main/main.module').then((m) => m.MainModule),
    canActivate: [Authorize]
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then((m) => m.AccountModule)
  },
  { path: '**', redirectTo: '' }
];
